import axios from "./axios"

class FilesLoader {
  constructor() {

  }

  getFile(url) {
    return new Promise((resolve, reject) => {
      axios.getBlob(url).then((response) => {
        resolve(response)
      })
    })
  }

  getFileUrl(url) {
    return new Promise((resolve, reject) => {
      console.log(url)
      this.getFile(url).then((response) => {
        resolve(URL.createObjectURL(response))
      })
    })
  }
}

export default new FilesLoader()